import React, { useState, useEffect } from 'react'
import Header from '../../components/template/Header'
import Footer from '../../components/template/Footer'
import BreadCrumb from '../../components/template/BreadCrumb'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { BlogData } from '../../models/blog'

function SingleBlog() {
    let { slug } = useParams();
    console.log(slug);
    const [blog, setBlog] = useState(null);
    useEffect(() => {
        const filteredBlog = BlogData.filter((item) => item.slug === slug);
        if (filteredBlog.length > 0) {
            setBlog(filteredBlog[0]);
        } else {
            setBlog(null);
        }
        window.scrollTo(0, 0);
    }, [slug]);

    // blog.desc = blog.desc.replace(/\\r\\n/g, "<br />");

    return (
        <div>
            <Header active="BLOGS" />
            {blog && (
                <>
                    <BreadCrumb title={blog.title} />
                    <section className="single-blog-detailed">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blog-detailed-img">
                                        <img src={blog.image} alt="" />
                                    </div>
                                    <div className="blog-detailed-content pt-4">
                                        {/* <div className="d-flex justify-content-between">
                                            <div className="blog-card-title project-heading pt-2">
                                                <h3>{blog.title}</h3>
                                            </div>
                                            <div className="blog-card-date project-heading pt-2">
                                                <p>{blog.date}</p>
                                            </div>
                                        </div> */}
                                        <div className="blog-detailed-desc">
                                            <p dangerouslySetInnerHTML={{
                                                __html: `${blog.desc.replace(/\n/g, "<br /><br/>")}`
                                            }}></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            <Footer />
        </div>
    )
}

export default SingleBlog