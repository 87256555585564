import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function LittleAngels() {
  return (
    <section className='giving ptb-50'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sec-heading'>
              <h2>OUR LITTLE ANGELS</h2>
            </div>
          </div>
          <div className='col-md-12 align-center little-angel-pt-24 mt-5'>
            <div className='logo-image'>
              <img src='assets/images/logo.png' />
              <h2>A Tribute to our Brave Souls.</h2>
            </div>

            <div className='little-angels-para pt-2  align-center'>
              <p>
                In the memory of our brave souls who fought till the last
                breath. Kids, you will always be in our hearts. We hope you are
                in a better place. We will forever cherish the moment spent
                together. Adios.
              </p>
            </div>
          </div>
        </div>
        <div className='main-giving'>
          <div className='row items-center d-flex justify-content-center'>
            <div className='col-md-4 mx-5'>
              <div className='little-angel-card'>
                <div className='angel-card-inside-box'>
                  <div className='angel-card-featured-img'>
                    <img src='/assets/images/little_angel_1.png' alt='' />
                  </div>
                  <div className='angel-card-content'>
                    <div className='footer-flex'>
                      <Link to='/our-little-angels'>
                        <div className='little-angel-title'>Mishti Rajora</div>
                      </Link>
                      <div className='little-angel-age'>9 Years</div>
                    </div>
                    <div className='little-angel-disease'>
                      <p>Acute Promyelocytic Leukemia (APML)</p>
                    </div>
                    <div className='little-angel-heading'>
                      <p>
                        <strong>Mishti Rajora-Forever in our hearts</strong>
                      </p>
                    </div>
                    <div className='little-angel-short_desc'>
                      <p>
                        Our hearts go out to the Rajora family during this
                        difficult time, and we extend our deepest condolences
                        for the loss of their precious bundle of joy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 mx-5'>
              <div className='little-angel-card'>
                <div className='angel-card-inside-box'>
                  <div className='angel-card-featured-img'>
                    <img src='/images/sadhna-pariyar.jpeg' alt='' />
                  </div>
                  <div className='angel-card-content'>
                    <div className='footer-flex'>
                      <Link to='/our-little-angels'>
                        <div className='little-angel-title'>Sadhna Pariyar</div>
                      </Link>
                      <div className='little-angel-age'>2 Years</div>
                    </div>
                    <div className='little-angel-disease'>
                      <p>Blood Cancer</p>
                    </div>
                    <div className='little-angel-heading'>
                      <p>
                        <strong>In Loving Memory of Sadhna Pariyar</strong>
                      </p>
                    </div>
                    <div className='little-angel-short_desc'>
                      <p>
                        Sadhna Pariyar, a courageous two-year-old girl from
                        Rhenock Bazar B, Pakyong, Sikkim, bravely fought a
                        severe case of blood cancer. Sadly, she has passed away.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LittleAngels;
