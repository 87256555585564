import React from "react";
import { connect } from "react-redux";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import BreadCrumb from "../../components/template/BreadCrumb";
import { Link } from "react-router-dom";
import Statics from "../../components/template/Statics";
const ThankYou = (props) => {
  return (
    <div>
      <Header active="ABOUT" />
      <BreadCrumb title={"Thank You"} />
      <section className="who-we ptb-50">
        <div className="container">
          <div className="who-we-are">
            <div className="row">
              <div className="col-md-12">
                <h3>Thank You</h3>
                <p>
                  Thank you for your great generosity! We, at National NGO,
                  greatly appreciate your donation. It is so heartening when we
                  receive a gift from a generous donor. Your support helps to
                  further our mission through different projects. Your support
                  is invaluable to us, thank you again!
                </p>
                <p>Thank you for your Donation</p>
                <p>Please check you email for the payment receipt with 80G</p>
                <p>
                  All donation amount towards this campaign is updated once a
                  day and max is 7 days
                </p>
                <p>
                  We will update you with the patient treatment status soon via
                  Email or WhatsApp
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
