import { db } from "../../shared/config";
import {
    collection,
    addDoc,
    getDocs,
    getDoc,
    doc,
    updateDoc,
    deleteDoc,
    query,
    orderBy,
    startAfter,
    endBefore,
    limitToLast,
    limit,
    Timestamp,
    where,
} from "firebase/firestore";
import {
    GET_SPECIALDONATIONS,
    ADD_SPECIALDONATION,
    GET_SPECIALDONATION,
    RESET_SPECIALDONATION,
    SPECIALDONATIONS_ERROR,
} from "../types/specialdonation_type";

import { setAlert } from "./alert";
import { LIMIT } from "../../domain/constant";
//GET SPECIALDONATIONS
const specialdonationsRef = collection(db, "specialdonations");
export const getSpecialdonations =
    ({ term, termField, orderValue = "createdAt", orderType = "desc" }) =>
        async (dispatch) => {
            try {
                let searchQuery = null;
                if (term) {
                    searchQuery = query(specialdonationsRef, where(termField, "==", term));
                } else {
                    searchQuery = query(
                        specialdonationsRef,
                        orderBy(orderValue, orderType),
                        limit(LIMIT)
                    );
                }
                const querySnapshot = await getDocs(searchQuery);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                dispatch({
                    type: GET_SPECIALDONATIONS,
                    payload: data,
                });
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something went wrong", "danger"));
                dispatch({
                    type: SPECIALDONATIONS_ERROR,
                });
            }
        };

export const getNextSpecialdonations =
    ({ term, termField, last, orderValue = "createdAt", orderType = "desc" }) =>
        async (dispatch) => {
            try {
                let searchQuery = null;
                if (term) {
                    searchQuery = query(
                        specialdonationsRef,
                        orderBy(orderValue, orderType),
                        startAfter(last[orderValue]),
                        limit(LIMIT),
                        where(termField, "==", term)
                    );
                } else {
                    searchQuery = query(
                        specialdonationsRef,
                        orderBy(orderValue, orderType),
                        startAfter(last[orderValue]),
                        limit(LIMIT)
                    );
                }
                const querySnapshot = await getDocs(searchQuery);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                dispatch({
                    type: GET_SPECIALDONATIONS,
                    payload: data,
                });
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something went wrong", "danger"));
                dispatch({
                    type: SPECIALDONATIONS_ERROR,
                });
            }
        };
export const getPreviousSpecialdonations =
    ({ term, termField, first, orderValue = "createdAt", orderType = "desc" }) =>
        async (dispatch) => {
            try {
                let searchQuery = null;
                if (term) {
                    searchQuery = query(
                        specialdonationsRef,
                        orderBy(orderValue, orderType),
                        endBefore(first[orderValue]),
                        limitToLast(LIMIT),
                        where(termField, "==", term)
                    );
                } else {
                    searchQuery = query(
                        specialdonationsRef,
                        orderBy(orderValue, orderType),
                        endBefore(first[orderValue]),
                        limitToLast(LIMIT)
                    );
                }

                const querySnapshot = await getDocs(searchQuery);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                dispatch({
                    type: GET_SPECIALDONATIONS,
                    payload: data,
                });
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something went wrong", "danger"));
                dispatch({
                    type: SPECIALDONATIONS_ERROR,
                });
            }
        };

export const addSpecialdonation = (data) => async (dispatch) => {
    try {
        data.createdAt = Timestamp.fromDate(new Date());
        const docRef = await addDoc(specialdonationsRef, data);
        dispatch({
            type: ADD_SPECIALDONATION,
        });
        dispatch(setAlert("Specialdonation added successfully", "success"));
        window.location.href = `/thankyou-special-donation`
    } catch (e) {
        console.error("Error adding document: ", e);
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: SPECIALDONATIONS_ERROR,
        });
    }
};

export const getSpecialdonation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_SPECIALDONATION,
        });
        const docRef = doc(db, "specialdonations", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            data.id = id;
            dispatch({
                type: GET_SPECIALDONATION,
                payload: data,
            });
        } else {
            dispatch(setAlert("Something went wrong", "danger"));
            dispatch({
                type: SPECIALDONATIONS_ERROR,
            });
        }
    } catch (err) {
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: SPECIALDONATIONS_ERROR,
        });
    }
};
export const editSpecialdonation = (id, data) => async (dispatch) => {
    try {
        const docSnap = await updateDoc(doc(db, "specialdonations", id), data);
        dispatch(setAlert("Specialdonation updated successfully", "success"));
    } catch (err) {
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: SPECIALDONATIONS_ERROR,
        });
    }
};

export const deleteSpecialdonation = (id) => async (dispatch) => {
    try {
        const docSnap = await deleteDoc(doc(db, "specialdonations", id));
        dispatch(setAlert("Specialdonation deleted successfully", "success"));
    } catch (err) {
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: SPECIALDONATIONS_ERROR,
        });
    }
};
