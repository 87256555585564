import React, { useState } from 'react'
import Spinner from "../layout/Spinner";
import Slider from "react-slick";
import YoutubeVideoCard from './YoutubeVideoCard';
import { YoutubeLink } from '../../models/youtubeLink';



function YoutubeVideos() {


    var settings = {
        infinite: true,
        slidesToShow: 3,
        autoplay: true,
        arrow: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div>
            <section className="who-we ptb-45">
                <div className="container">
                    <div className="who-we-are">
                        <div className="">
                            <Slider  {...settings}>
                                {
                                    YoutubeLink && YoutubeLink.map((item) => {
                                        return (
                                            <YoutubeVideoCard yt_link={item.yt_link} />
                                        )
                                    })
                                }
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default YoutubeVideos