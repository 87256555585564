import React from 'react'
import { Link } from "react-router-dom";


function OurApproach() {
    return (
        <>
            <section className="who-we ptb-50">
                <div className="container">
                    <div className="who-we-are">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="who-we-image">
                                    <img src="assets/images/who-we-are-main.png" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h3>OUR APPROACH</h3>
                                <p>
                                    The National Organization for Social Empowerment has made a considerable shift in its programming approach over the years. From providing basic services and social protection to focusing on health (including nutrition), education, and empowering the poor and vulnerable groups.
                                </p>
                                <p>
                                    The National Organization for Social Empowerment has evolved into a rights-based organization in order to address the underlying causes of poverty. Our focus is explicitly on the well-being, social position, and rights of underprivileged children and people, persons with special abilities, and women and girls from marginalized communities.
                                </p>
                                <p>
                                    The National Organization for Social Empowerment focuses on developing the potential of underprivileged children and people, people with special abilities, and women and girls to drive long-lasting equitable changes. We strategically emphasize promoting quality healthcare, a hunger-free world, inclusive education, gender equity, sustainable livelihood opportunities, and disaster relief.
                                </p>
                                <p>
                                    Our efforts are focused on fighting against the underlying causes of poverty, building secure and resilient communities, and ensuring a life of dignity for all underprivileged children and people, persons with special abilities, and women and girls from marginalized and vulnerable communities.
                                </p>
                                <p>
                                    Moving forward, our robust approach will continue to systematically examine social problems, issues, or trends with the aim of prompting changes that are being analysed, including gender transformative value chain approaches, leadership and life skills strengthening, building capacities and leadership roles at multiple levels, advocacy on national (at present) and international (later) platforms, and facilitating links and dialogues between public, private, and civil society.
                                </p>
                                <div className="hungry">
                                    <Link className="btn btn-donate-lg" to="/projects/national-ngo-kitchen" >FEED A HUNGRY CHILD</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurApproach