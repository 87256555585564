import React from "react";

function Statics() {
  return (
    <section className="giving ptb-50">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sec-heading">
              <h2>GIVING YOU CAN TRUST</h2>
            </div>
          </div>
        </div>
        <div className="main-giving">
          <div className="row">
            <div className="col-md-6 vl">
              <div className="logo-image">
                <img src="assets/images/logo.png" />
                <p>
                National OrganisationFor Social Empowerment was established in 2012, we have been continually working for social integration of underprivileged children & people, persons with special ability and women & girls of our community. We are a group of positive thinkers with a mutual vision. We believe that a world without barriers is a right not luxury.
                </p>
              </div>
            </div>
            <div className="col-md-6 mx-auto">
              <div className="main-trust-flex">
                <div className>
                  <h3>70M+</h3>
                  <span>Raised for nonprofits</span>
                </div>
                <div className>
                  <h3>2.3M+</h3>
                  <span>Donors have contributed to causes </span>
                </div>
              </div>
              <div className="main-trust-flex pt-50">
                <div className>
                  <h3>10M+</h3>
                  <span>Live impacted</span>
                </div>
                <div className>
                  <h3>Trusted</h3>
                  <span>by 150+ corporates and brands </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statics;
