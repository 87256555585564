import React from 'react'
import Header from '../../components/template/Header'
import Footer from '../../components/template/Footer'
import BreadCrumb from '../../components/template/BreadCrumb'
import BlogCard from '../../components/blogs/BlogCard'
import { BlogData } from '../../models/blog'

function Blogs() {
  const Blogs = BlogData;
  return (
    <div>

      <Header active="Blogs" />
      <BreadCrumb title={"Blogs"} />

      <section className="current ptb-30">
        <div className="container">
          <div className="current-project">
            <div className="row">
              <div className="col-md-4">
                {/* <CauseCard item={item} /> */}
                {
                  Blogs && Blogs.map((item) => {
                    return (
                      <BlogCard item={item} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Blogs