import React from "react";
import { connect } from "react-redux";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import BreadCrumb from "../../components/template/BreadCrumb";
import { Link } from "react-router-dom";
import Statics from "../../components/template/Statics";
const WhoWeAre = (props) => {
  return (
    <div>
      <Header active="ABOUT" />
      <BreadCrumb title={"WHO WE ARE"} />
      <section className="who-we ptb-50">
        <div className="container">
          <div className="who-we-are">
            <div className="row">
              <div className="col-md-7">
                <h3>WHO WE ARE</h3>
                <p>
                  Welcome to the National Organization for Social Empowerment (NOFSE). We're not just an NGO; we're a team of dreamers united by a powerful vision: "inclusiveness." We firmly believe that a world without barriers should be a basic right, not a privilege. Our mission is clear: to ensure that disability doesn't mean disadvantage.
                </p>
                <p>
                  Since our inception in 2012, we've been steadfastly dedicated to integrating people with disabilities into society. Though we may be a small group, our commitment knows no bounds. We serve as facilitators, counsellors, friends, and teachers, wearing multiple hats to support our community.
                </p>
                <p>
                  But that's not all. At NOFSE, we've extended our reach to create a more inclusive world. Our national NGO kitchen feeds thousands daily, ensuring no one goes hungry. Through 'Pehli Udan,' we're nurturing the young minds of slum kids, providing them with the wings to soar. And for women, we're unlocking opportunities with sewing classes, computer education, English-speaking classes, and more.
                </p>
                <p>Join us on this inspiring journey as we strive to make a difference, "one act of kindness at a time."</p>
                <div className="hungry">
                  <Link className="btn btn-donate-lg" to="/projects/national-ngo-kitchen" >FEED A HUNGRY CHILD</Link>
                </div>
              </div>
              <div className="col-md-5">
                <div className="who-we-image">
                  <img src="assets/images/who-we-are-main.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="giving ptb-50">
        <Statics />
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WhoWeAre);
