import React from 'react'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import Counter from '../components/template/Counter'
import FeaturedIn from '../components/template/FeaturedIn'
import Footer from '../components/template/Footer'
import Header from '../components/template/Header'
import HomepageBanners from '../components/template/HomepageBanners'
import {
  getEvents,
  getNextEvents,
  getPreviousEvents,
} from '../store/actions/events_action'
import HomepageFeatured from '../components/template/HomepageFeatured'
import HomepageStories from '../components/template/HomepageStories'
import Statics from '../components/template/Statics'
import Testimonials from '../components/template/Testimonials'
import HomepageCurrentBeneficiaries from '../components/template/HomepageCurrentBeneficiaries'
import WhoWeAre from '../components/template/WhoWeAre'
import { useEffect } from 'react'
import { getBanners } from '../store/actions/banners_action'
import { getStories } from '../store/actions/stories_action'
import { getCauses } from '../store/actions/causes_action'
import { getTestimonials } from '../store/actions/testimonials_action'
import GalleryCard from '../components/cards/GalleryCard'
import Newsletter from '../components/template/Newsletter'
import OurApproach from '../components/template/OurApproach'
import OurGoal from '../components/template/OurGoal'
import YoutubeVideos from '../components/template/YoutubeVideos'
import LittleAngels from '../components/template/LittleAngels'

const Home = ({
  getBanners,
  event: { loading, events },
  getCauses,
  getStories,
  getEvents,
  banner,
  storie,
  cause,
  getTestimonials,
  testimonial,
}) => {
  useEffect(() => {
    if (!banner.banners) {
      getBanners({})
    }
    getStories({})
    if (!cause.causes) {
      getCauses({})
    }
    if (!testimonial.testimonials) {
      getTestimonials({})
    }
  }, [])
  useEffect(() => {
    async function allQuery() {
      getEvents({})
    }
    allQuery()
  }, [])
  return (
    <>
      <Header active={'HOME'} />
      <HomepageBanners banners={banner.banners} loading={banner.loading} />
      <HomepageFeatured />
      <HomepageStories
        stories={storie.stories}
        loading={storie.loading} />
      <WhoWeAre />

      <HomepageCurrentBeneficiaries
        causes={cause.causes}
        loading={cause.loading}
      />
      <OurApproach />
      <Statics />
      <OurGoal />

      {
        // <Newsletter />
      }
      <Counter />
      <YoutubeVideos />
      <Testimonials
        testimonials={testimonial.testimonials}
        loading={testimonial.loading}
      />
      <FeaturedIn />
      <section className="current ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sec-heading">
                <h2>Our Events</h2>
              </div>
            </div>
          </div>
          <div className="current-project">
            <div className="row">
              {events &&
                events.map((item, index) => {
                  if (index < 6) {
                    return (
                      <div className="col-md-4">
                        <GalleryCard item={item} />
                      </div>
                    )
                  }
                })}
            </div>
            <div className="mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center">
                      <Link to={`/events`} className="btn btn-donate-lg">
                        {" "}
                        View All{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LittleAngels />
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  banner: state.banner,
  storie: state.storie,
  cause: state.cause,
  testimonial: state.testimonial,
  event: state.event,
})

const mapDispatchToProps = {
  getBanners,
  getEvents,
  getStories,
  getCauses,
  getTestimonials,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
