import React from 'react'

function Counter() {
  return (
    <section className="counter ">
      <div className="counter-banner">
        <div className="counter-banner-over" />
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-center">
                <div className="counter-img">
                  <img src="assets/images/success.png" />
                </div>
                <div className="counter-title">
                  <h3>Dry snacks packets provided to migrant labourers</h3>
                </div>
                <div className="counters">
                  <h3>7000+</h3>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="content text-center">
                <div className="counter-img">
                  <img src="assets/images/member.png" />
                </div>
                <div className="counter-title">
                  <h3>Families provided with Ration kits (during Covid19)</h3>
                </div>
                <div className="counters">
                  <h3>3000+</h3>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="content text-center">
                <div className="counter-img">
                  <img src="assets/images/implanted.png" />
                </div>
                <div className="counter-title">
                  <h3>Patients treated</h3>
                </div>
                <div className="counters">
                  <h3>250+</h3>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="content text-center">
                <div className="counter-img">
                  <img src="assets/images/total.png" />
                </div>
                <div className="counter-title">
                  <h3>People Fed</h3>
                </div>
                <div className="counters">
                  <h3>1250000+</h3>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="content text-center">
                <div className="counter-img">
                  <img src="assets/images/education.png" />
                </div>
                <div className="counter-title">
                  <h3>
                    480+ underprivileged children had been provided education
                  </h3>
                </div>
                <div className="counters">
                  <h3>480+</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Counter
