import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Spinner from '../layout/Spinner'

function HomepageBanners({ banners, loading }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 3600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1800,
  }
  return (
    <>
      {!loading ? (
        <Slider {...settings}>
          {banners &&
            banners.map((item) => {
              console.log(item);
              return (
                <section>
                  <div className="banner"
                    style={{
                      backgroundImage: `url(${item.featured_image})`,
                    }}>
                    <div className="container">
                      <div className="row center">
                        <div className="col">
                          <div className="banner-content center">
                            <h1 style={{ textAlign: "center" }} >{item.title}</h1>
                            <p style={{ color: "#ffffff" }} >{item.description}</p>
                            <div className="banner-button">
                              <Link to={item.link} className="btn btn-donate-lg">
                                DONATE NOW
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            })}
        </Slider>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </>
  )
}

export default HomepageBanners
