import React from "react";
import { connect } from "react-redux";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import BreadCrumb from "../../components/template/BreadCrumb";
import { Link } from "react-router-dom";
const OurTeam = (props) => {
  return (
    <div>
      <Header active="ABOUT" />
      <BreadCrumb title={"OUR TEAM"} />

      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/dr_sunil.jpeg"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Dr. Sunil Gupta</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>
                  - Senior Physiotherapist
                </p>
                <p style={{ color: "#FF6F11" }}>
                  - 13 years of Clinical Experience
                </p>
                <p>
                  Dr. Sunil Gupta is a dedicated Consultant Physiotherapist, holding degrees in BPT and MPT (Neurology), with a specialization in Sports Physiotherapy (FDFM). He joined our NGO on July 10, 2017, after a successful stint at the Apollo Clinic. Dr. Gupta's passion for social work and helping people is evident in his commitment to our cause. His expertise in physiotherapy and his compassion make him a valuable asset to our organization, where he continues to make a positive impact on the lives of those in need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/monika_team.png"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Monika Nautiyal</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>
                  - Senior Manager Backend operations
                </p>
                <p>
                  Miss Monika is the senior manager for backend operations. She has been working with National Organization for Social Empowerment for 8 plus years. Her willingness to help the less privileged inspired her to work for a nonprofit organization. She is actively involved with the foundation, especially in the field of education. She continues to work tirelessly, and help the foundation grow and touch as many lives as it can.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/krishan_gopal.png"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Krishan Gopal</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>
                  - Manager Programms
                </p>
                <p>
                  Mr.Gopal is the program manager at National Organization For Social Empowerment. His enthusiasm and passion for social service drove him to work for National Organization. His decade of experience in management in a children-based organization has helped the foundation in innumerable ways. He brings new ideas to the table, and his management skills in the field of nonprofits have proved his ability to manage the foundation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/JudesHarry.jpeg"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Judes Agenlo Harry</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>
                  - Manager Programms
                </p>
                <p>
                  Mr. Judes is our Programme Manager. His interest in the field of social service is very personal. His father was a victim of cancer and suffered from this deadly disease and unfortunately, he couldn’t save him. During the medical visits to the hospital with his father, he came across a lot of families who were suffering and going through the same situation as he was. His heartfelt love and dedication to helping all these people inspired him to get into the field of social service. Although his 16-plus prior experience in the field of choreography, disc jockey, events, counseling, and marketing may sound completely different from what he eventually pursued, however, his commitment to social service proved how spirited he is towards social welfare. His endless service and passion to help the needy, less privileged, and downtrodden being inspires everyone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/Ruchika.jpg"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Ruchika Bali</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>- Manager Project</p>
                <p>
                  Miss Ruchika Bali is our project manager. She is a liberal Arts graduate with 10-plus years of experience in International non-profit organizations. She is very passionate about social service; her passion leads her to be part of the National Organization for Social Empowerment. She is associated with NOSE for the past 6 years. She monitors all the projects closely and has proved her ability to manage and handle the projects from start to finish.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/sanjeev_nainawat.png"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Sanjeev Kumar Nainavat</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>- Manager Project</p>
                <p>
                  Mr. Sanjeev Kumar’s passion for social service led him to work for National Organization for Social Empowerment. His selfless love for the downtrodden and less privileged children further encouraged him to continue his education in social work. He completed his Master’s in Social Work, and his strong academic background helps the team to create and manage the projects from conception to delivery. He is associated with the organization for six-plus years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/manish_gera.png"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Manish Gera</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>- Manager Admin</p>
                <p>
                  Mr. Manish is our administrative manager, his role is to coordinate multiple office functions, supervise employees and create and maintain spreadsheets, databases, and report files. He ensures that every team member has fair hours that allow them to be effective and productive. Under his supervision, things ran smoothly, and office operations are handled with conviction. His 10+ years of experience have made him an incredible planner and a coordinator, and it's seen in his ability to streamline processes. Additionally, he assessed staff performances and provided them with coaching and guidance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 about-content">
                <img
                  src="/images/team/PraveshKumar.jpeg"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Pravesh Kumar</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>
                  - Manager Finance &amp; Accounts
                </p>
                <p>
                  Mr.Parvesh Kumar is our manager of finance and accounts. An M.com graduate with 10+ years of experience. He has a well-rounded skill set in financial analysis, and his incredible talent in handling financial reports and maintaining reports makes him an ideal fit. He works tirelessly to improve the projects and gets better results. He analyses the markets for business opportunities, such as the expansion of projects, corporate mergers, and acquisitions.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {
        // <section
        //   className="about-2 sec-padd3"
        //   style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
        // >
        //   <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
        //     <div className="container">
        //       <div className="row">
        //         <div className="col-md-4 about-content">
        //           <img
        //             src="/images/team/Varsha_Belwal.png"
        //             style={{
        //               maxWidth: "100%",
        //               border: "2px solid rgb(241, 241, 248)",
        //             }}
        //           />
        //         </div>
        //         <div className="col-md-8 about-content">
        //           <h3 style={{ padding: "10px 0px" }}>Varsha Belwal</h3>
        //           <p style={{ color: "rgb(102, 102, 102)" }}>
        //             - Head Human Resource
        //           </p>
        //           <p>
        //             Miss. Belwal holds an experience of 9+ years in Human Resources with a demonstrated history of working in the non-profit organization and human resources Industry. She completed her Graduation from Mahatma Gandhi University, Shillong. She is proficient in HR consulting, executive search, recruiting, and HR policies. She assigns and monitors staff functions. Responsible for recruiting, onboarding, and training employees. She is responsible for grievance handling employee relations. Her love for social service drove her to work with National Organization, she strongly believes in helping people and making a difference in the World. She endeavors to foster an environment of innovation, collaboration, and empowerment that honors the individuals' as well as teams' achievements.
        //           </p>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </section>
      }
      <section
        className="about-2 sec-padd3"
        style={{ padding: "15px 0px 0px", background: "rgb(248, 249, 246)" }}
      >
        <div style={{ padding: "20px 0px", background: "rgb(255, 255, 255)" }}>
          <div className="container">
            <div className="row">

              <div className="col-md-4 about-content">
                <img
                  src="/images/team/mohit-bhatt-edited.png"
                  style={{
                    maxWidth: "100%",
                    border: "2px solid rgb(241, 241, 248)",
                  }}
                />
              </div>
              <div className="col-md-8 about-content">
                <h3 style={{ padding: "10px 0px" }}>Mohit bhatt</h3>
                <p style={{ color: "rgb(102, 102, 102)" }}>
                  - Project IN charge
                </p>
                <p>
                  Mr.Bhatt is the project in charge at National N.G.O. He pursued Masters's in Social Work from IGNOU. He has been running multiple projects in the N.G.O. His hard work and dedication to social service are admirable. He has successfully worked on projects like the national N.G.O. kitchen and Pehli Udraan. He is very passionate about serving underprivileged children. Therefore, he ran these projects in the slum areas where it is needed the most. His honesty and devotion to serving needy people are exemplary. He aims to make general education available for kids who come from destitute families.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="call-out">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4>
                Start with helping one poor person and pave way for the bigger
                changes !
              </h4>
            </div>
            <div className="col-md-4">
              <Link className="thm-btn style-3" to="/become-a-volunteer">
                Become a Volunteer
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam);
