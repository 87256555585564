import React, { useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { addContact } from "../../store/actions/contacts_action";
import { Link } from "react-router-dom";

const ContactUs = ({ addContact }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <Header active="CONTACT_US" />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-heading">
                <h3>We'd love to hear from you</h3>
                <div className="contact-form">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      message: "",
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Required"),
                      email: Yup.string().required("Required"),
                      phone: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      await addContact(values);
                      resetForm();
                      setSubmitting(false);
                      window.location.href = `/thankyou-contacts`

                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className="row">

                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.name}
                                />
                                {formik.errors && formik.errors.donor_name && (
                                  <p className="text-danger">{formik.errors.donor_name}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email"
                                  name="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                />
                                {formik.errors && formik.errors.email && (
                                  <p className="text-danger">{formik.errors.email}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact Number"
                                  name="phone"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phone}
                                />
                                {formik.errors && formik.errors.phone && (
                                  <p className="text-danger">{formik.errors.phone}</p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  rows={3}
                                  placeholder="Notes (If Any)"
                                  name="message"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.message}
                                />
                              </div>
                            </div>
                            <div className="donate-button">
                              <button className="btn btn-donate" type="submit">
                                {isLoading ? "Processing..." : "Submit Now"}
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-solid">
                <div className="text-left quote">
                  <i className="fa fa-quote-left" />
                </div>
                <div className="text-center quote-c">
                  <h3>
                    A man's true wealth is the good he does in this world.
                  </h3>
                </div>
                <div className="text-right quote">
                  <i className="fa fa-quote-right" />
                </div>
                <div className="start">
                  <h3>Just A Little Help Can Make Their Hearts Happier!</h3>
                </div>
                <div className="donate-btn text-center mt-5">
                  <Link
                    className="btn btn-donate my-2 my-sm-0"
                    to="/donate-now"
                    style={{ padding: "12px 50px" }}
                    type="submit"
                  >
                    Donate Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* contact details */}
          <div className="contact-main-details pt-50">
            <div className="row">
              <div className="col-md-4">
                <div className="contain">
                  <div className="contain-box-c">
                    <i className="fa fa-phone-square" />
                    <h3>CALL US</h3>
                    <p>18001034683</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contain">
                  <div className="contain-box-c">
                    <i className="fa fa-envelope" />
                    <h3>EMAIL US</h3>
                    <p>support@nationalngo.org</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contain">
                  <div className="contain-box-c">
                    <i className="fa fa-map-marker" />
                    <h3>ADDRESS</h3>
                    <p>
                      TA – 204, 1st Floor, Ravidas Marg, Tughlakabad Extension,
                      New Delhi-110019
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map pt-50">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28043.15926825334!2d77.24006053955078!3d28.527847500000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce16eddc9eef7%3A0xb3f2e5c6aac36a4a!2sNational%20Organisation%20for%20Social%20Empowerment!5e0!3m2!1sen!2sin!4v1643349723179!5m2!1sen!2sin"
          width="100%"
          height={500}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addContact };

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
