import React from "react";
import { connect } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { addDonation } from "../../store/actions/donations_action";
import { Formik, Form } from "formik";
import * as Yup from "yup";
export const DonationForm = ({ addDonation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const makeDonation = async ({
    donor_name,
    email,
    phone,
    pan,
    volunteer,
    amount,
    city,
    address,
    pincode,
    state,
    message,
  }) => {
    setIsLoading(true);
    window.location.href = `https://pages.razorpay.com/DonateNGO?amount=${amount}&name=${donor_name}&email=${email}&pan_number=${pan}&address=${address}`;

    // addDonation(formData, cause[0].razorpay_link);
    const order = await axios.get(
      `https://nationalngo.net/razorpay-single/pay.php?amount=${amount}`
    );
    // console.log(order.data);

    var options = {
      key: "rzp_live_YjsybpU5dNZ5B7", // Enter the Key ID generated from the Dashboard
      amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "National NGO",
      description: "",
      image: "https://nationalngo.org/images/logo.png",
      order_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const postData = {
          donor_name: donor_name ? donor_name : "",
          email: email ? email : "",
          phone: phone ? phone : "",
          pan: pan ? pan : "",
          volunteer: volunteer ? volunteer : "",
          amount: amount ? amount : "",
          city: city ? city : "",
          address: address ? address : "",
          pincode: pincode ? pincode : "",
          state: state ? state : "",
          message: message ? message : "",
          transaction_id: response.razorpay_payment_id,
          referrer: window.location.referrer ? window.location.referrer : "",
          page: window.location.href ? window.location.href : "",
        };

        await addDonation(postData);
        window.location.href = `/thank-you?amount=${postData.amount}&page=${window.location.href}`;
      },
      prefill: {
        name: donor_name,
        email: email,
        contact: phone,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert("Payment Failed Please try again");
    });
    rzp1.open();

    setIsLoading(false);
  };
  return (
    <div>
      <Formik
        initialValues={{
          donor_name: "",
          email: "",
          phone: "",
          pan: "",
          volunteer: "",
          amount: new URLSearchParams(window.location.search).get("amount")
            ? new URLSearchParams(window.location.search).get("amount")
            : "",
          city: "",
          address: "",
          pincode: "",
          state: "",
          message: "",
        }}
        validationSchema={Yup.object({
          amount: Yup.string().required("Required"),
          donor_name: Yup.string().required("Required"),
          email: Yup.string().required("Required"),
          phone: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await makeDonation(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => {
          console.log(formik);
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Donation Amount"
                      name="amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                    />
                    {formik.errors && formik.errors.amount && (
                      <p className="text-danger">{formik.errors.amount}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Donor Name"
                      name="donor_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.donor_name}
                    />
                    {formik.errors && formik.errors.donor_name && (
                      <p className="text-danger">{formik.errors.donor_name}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors && formik.errors.email && (
                      <p className="text-danger">{formik.errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Contact Number"
                      name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.errors && formik.errors.phone && (
                      <p className="text-danger">{formik.errors.phone}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="pan"
                      className="form-control"
                      placeholder="Pan Number"
                      name="pan"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pan}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Volunteer Name"
                      name="volunteer"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.volunteer}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      name="state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pin Code"
                      name="pincode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pincode}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Notes (If Any)"
                      name="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                    />
                  </div>
                </div>
                <div className="donate-button">
                  <button className="btn btn-donate" type="submit">
                    {isLoading ? "Processing..." : "Donate Now"}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addDonation };

export default connect(mapStateToProps, mapDispatchToProps)(DonationForm);
