import React from 'react'

function BlogCard({ item }) {
    return (
        <div>
            <div className="blog-card">
                <div className="card-inner-box">
                    <div className="blog-card-img">
                        <img src={item.image} alt="" />
                    </div>
                    <div className="blog-card-content">
                        <div className="d-flex justify-content-between">
                            <div className="blog-card-title project-heading pt-2">
                                <h3>{item.title}</h3>
                            </div>
                            <div className="blog-card-date project-heading pt-2">
                                <p>{item.date}</p>
                            </div>
                        </div>
                        <div className="blog-card-short-desc story">
                            <p>{item.short_desc}</p>
                        </div>
                    </div>
                    <div className="blog-card-button pt-2">
                        <a href={`/blog/${item.slug}`}>Read More</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCard