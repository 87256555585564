import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/template/Header';
import Footer from '../../components/template/Footer';
import BreadCrumb from '../../components/template/BreadCrumb';
import { Link } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import { LittleAngels } from '../../models/littleangel';

function AllLittleAngles() {
  const [littleAngles, setLittleAngles] = useState(LittleAngels);
  return (
    <div>
      <Header active='SUCCESS_STORIES' />
      <BreadCrumb title={'Our Little Angels'} />
      <section className='current ptb-30'>
        <div className='container'>
          <div className='current-project angel-border'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='little-angel-img'>
                  <img src='/assets/images/little_angel_1.png' alt='' />
                </div>
              </div>
              <div className='col-md-7'>
                <div className='little-angel-title'>Mishti Rajora</div>
                <div className='little-angel-age'>9 Years</div>
                {/* <div className="little-angel-documents">
                                    <p className="medical-documents">Medical Documents </p>
                                </div> */}
                <div className='little-angel-description'>
                  <div className='little-angel-heading'>
                    <p>
                      <strong>Mishti Rajora-Forever in our hearts</strong>
                    </p>
                  </div>
                  <div className='little-angel-para'>
                    <p>
                      In a heartbreaking turn of events, we got the news that
                      Mishti Rajora, the brave 9-year-old battling Acute
                      Promyelocytic Leukemia (APML), is no longer with us.
                    </p>

                    <p>
                      Despite her father, Arvind Kumar's relentless efforts and
                      the support sought from the National NGO, Mishti's valiant
                      fight against this devastating disease has come to an end.
                    </p>

                    <p>
                      Arvind Kumar, an unemployed father, faced insurmountable
                      challenges in arranging funds for his daughter's
                      treatment, but the National NGO stepped in with a helping
                      hand.
                    </p>

                    <p>
                      Our hearts go out to the Rajora family during this
                      difficult time, and we extend our deepest condolences for
                      the loss of their precious bundle of joy.
                    </p>

                    <p>
                      May Mishti rest in peace, and may the family find strength
                      in the memories they shared.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='current-project angel-border mt-5'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='little-angel-img'>
                  <img src='/images/sadhna-pariyar.jpeg' alt='' />
                </div>
              </div>
              <div className='col-md-7'>
                <div className='little-angel-title'>Sadhna Pariyar</div>
                <div className='little-angel-age'>2 Years</div>
                {/* <div className="little-angel-documents">
                                    <p className="medical-documents">Medical Documents </p>
                                </div> */}
                <div className='little-angel-description'>
                  <div className='little-angel-heading'>
                    <p>
                      <strong>In Loving Memory of Sadhna Pariyar</strong>
                    </p>
                  </div>
                  <div className='little-angel-para'>
                    <p>
                      Sadhna Pariyar, a courageous two-year-old girl from
                      Rhenock Bazar B, Pakyong, Sikkim, bravely fought a severe
                      case of blood cancer. Sadly, she has passed away. Our
                      hearts go out to her family during this incredibly
                      difficult time. We offer our deepest condolences and
                      prayers for comfort and strength as they grieve the loss
                      of their beloved Sadhna.
                    </p>

                    <p>
                      Her journey touched many hearts, and her bravery in facing
                      such a challenging illness was an inspiration to all who
                      knew her story. Although her time with us was short, the
                      impact of her life will be remembered fondly by those who
                      loved her and those who followed her journey.
                    </p>

                    <p>
                      May Sadhna's soul find peace and rest, and may her family
                      find solace in the cherished memories they hold. Our
                      thoughts are with them as they navigate through this
                      profound loss.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AllLittleAngles;
